import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { Breadcrumb } from 'antd';

class CustomBreadcrumb extends PureComponent {
  render() {
    const { location, routerList } = this.props;
    const pathSnippets = location.pathname.split('/').filter(i => i);
    const extraBreadcrumbItems = pathSnippets.map((_, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
      const routeNote = routerList.filter(item => item.path === url)[0];
      if (routeNote) {
        return (
          <Breadcrumb.Item key={url}>
            <span>{routeNote.name}</span>
          </Breadcrumb.Item>
        );
      }
      return null;
    });
    const breadcrumbItems = [
      <Breadcrumb.Item key='home'>
        <span>首页</span>
      </Breadcrumb.Item>
    ].concat(extraBreadcrumbItems);
    return (
      <Breadcrumb style={{ marginBottom: 20 }}>{breadcrumbItems}</Breadcrumb>
    );
  }
}

export default withRouter(CustomBreadcrumb);
