export const APP_TOKEN_KEY = 'pier-admin-token';

export const LIST_DEFAULT_TAKE = 100;
export const MAX_LENGTH = 10;

export const FIELD_TYPE = {
  INPUT: 'input',
  AUTOCOMPLETE: 'autoComplete',
  TRANSFER: 'transfer',
  SELECT: 'select',
  DATEPICKER: 'datePicker',
  TEXTAREA: 'textarea',
  DATERANGE: 'dateRange',
  RADIO: 'radio',
  GROUP: 'group',
  SEARCH_SELECT: 'searchSelect',
  SLIDER: 'slider',
};

export const PAGE_OPTION = ['10', '20', '50', '100'];
export const DownloadUrl = 'https://itunes.apple.com/cn/app/id1440367223';
export const DownAndroidLoadUrl = 'https://sj.qq.com/myapp/detail.htm?apkName=com.pier88health.app';

export const Country = {
  USA: 'USA',
  CHINA: 'China'
};

export const CREATOR_TYPE = {
  Individual: 'Individual',
  Institution: 'Institution'
};

export const UserTypeEnums = {
  Physician: 'Physician',
  Student: 'Student',
  Pharmacist: 'Pharmacist',
  Recruiter: 'Recruiter',
  HospitalManagement: 'HospitalManagement',
  Other: 'Other'
};

export const USER_TYPE = {
  Physician: '医生',
  Student: '医学生',
  Pharmacist: '药剂师',
  Recruiter: '招聘人员',
  HospitalManagement: '医院行政管理人员',
  Other: '其他'
};

export const UserStatusEnums = {
  PendingVerify: 'PendingVerify',
  Registered: 'Registered',
  Tentative: 'Tentative',
  Verified: 'Verified',
  FailedVerify: 'FailedVerify'
};

export const USER_STATUS = {
  Registered: '未提交',
  PendingVerify: '待审核',
  Verified: '已认证',
  FailedVerify: '未通过'
};

export const SELECT_STATUS = {
  NotSubmit: '未提交',
  PendingVerify: '待审核',
  Verified: '已认证',
  FailedVerify: '未通过'
}

export const SERVICE_AUTH_STATUS = {
  UnVerified: '未提交',
  PendingVerify: '待审核',
  Verified: '已认证',
  FailedVerify: '未通过'
};

export const KOL_TYPE = {
  Default: 'Default',
  KOL: 'Expert'
};
export const KOL_TYPE_SHOW = {
  Default: '默认',
  KOL: '专家'
};
export const ACCOUNT_ROLE = {
  Default: '默认',
  Expert: 'KOL'
};

export const KOL_IDENTITIES = {
  Expert: { Level: 'SilverLevel', Type: 'Expert', IconFileName: 'kol_silver_level.png' }
};

export const ContentStatusEnums = {
  Reported: 'Reported',
  Blocked: 'Blocked',
  Active: 'Active'
};

export const CONTENT_TYPE = {
  Reported: '被举报',
  Blocked: '被屏蔽',
  Active: '正常',
  Draft: '定时发布'
};
export const RECOMMEND_TYPE = {
  yes: '已推荐',
  un: '已取消',
  no: '未推荐'
};
export const ORGANIZATION_TYPE = {
  Individual: '个人',
  Institution: '机构'
};
export const ContentEnums = {
  Blocked: 'Blocked',
  Active: 'Active'
};

export const ACCOUNT_STATUS = {
  Normal: '正常',
  LockedOut: '锁定',
  Suspended: '禁用'
};

export const AccountEnums = {
  Normal: 'Normal',
  LockedOut: 'LockedOut',
  Suspended: 'Suspended'
};

export const DeclineReasonEnums = {
  InfoNotMatch: 'InfoNotMatch',
  InsufficientInfo: 'InsufficientInfo',
  InfoChanged: 'InfoChanged',
  Other: 'Other'
};

export const REPORTS_REASON = {
  Other: '其它'
};

export const DECLINE_REASON = {
  InfoNotMatch: '提交资料与当前经历不符合，请重新提交正确资料进行审核，完成认证',
  InsufficientInfo: '认证资料不符合要求：应当提供当前经历的执业医师执照、工牌或者同类凭证',
  InfoChanged: '认证资料变更或已失效。请重新提交正确资料进行审核，完成认证。'
};

export enum LanguageEnum {
  zh = 'zh',
  en = 'en',
  und = 'und'
}

export const LanguageName = {
  zh: {
    Name: 'zh',
    DisplayText: '中文'
  },
  en: {
    Name: 'en',
    DisplayText: '英文'
  }
};

export const OriginalType = {
  departmentTag: {
    Name: 'Department',
    DisplayText: '科室标签'
  },
  icdTag: {
    Name: 'Icd',
    DisplayText: '疾病标签'
  }
};

export const ORIGINAL_TYPE = {
  Department: '科室标签',
  Icd: '疾病标签',
  AdminDefined: '自定义标签'
};

export const TAG_LABEL_TYPE = {
  AdminDefined: 'AdminDefined',
  Department: 'Department',
  Icd: 'Icd'
};

// 机构管理 维护员申请
export const AuditEnums = {
  Pending: 'Pending',
  Approved: 'Approved',
  Denied: 'Denied'
};

export const AUDIT_STATUS = {
  Pending: '待审核',
  Approved: '已通过',
  Denied: '已拒绝'
};

export const NOTPASS_REASON = {
  LimitOverflow: '维护员数量已达上线'
};

export const BusinessTypeEnum = {
  Hospital: 'Hospital',
  Association: 'Association',
  Education: 'Education',
  Recuiter: 'Recuiter',
  Pharmary: 'Pharmary',
  DeviceProvider: 'DeviceProvider',
  Logistics: 'Logistics',
  Publishing: 'Publishing'
};

export const AddressType = {
  country: 'country',
  state: 'state',
  city: 'city'
};
export const BUSINESS_TYPE = {
  Association: '协会',
  Hospital: '医院',
  Education: '教育培训',
  Recuiter: '招聘机构',
  Pharmary: '医药企业',
  DeviceProvider: '设备供应',
  Logistics: '物流企业',
  Publishing: '出版机构'
};

export const SEARCH_SELECT_TYPES = {
  TOPIC: 'Topic',
  USER: 'User',
  GROUP: 'Group',
  IMPERSONATE_USER: 'IMPERSONATE_USER',
  SPECIALITY: 'SPECIALITY',
  ICDIDS: 'ICDIDS',
  TAG: 'TAG',
  CIRCLE: 'CIRCLE',
  DEPARTMENT: 'DEPARTMENT',
  COURSEWARETOPIC: 'CourseWareTopic',
  COURSEWARETAGS: 'CourseWareTags',
  HOSPITAL: 'HOSPITAL',
  MEMBER: 'Member',
  OPEN_CUSTOMER: 'OpenCustomer',
  SEARCH_USER: 'SearchUser',
  ORIGINAL_TAGS: 'OriginalTags',
  BUSINESS_TYPE: 'BusinessType',
  ALL_DEPARTMENT: 'All_Department',
  POSITION: 'Position',
  DEGREE_CHINA: 'Degree_China',
  DEGREE_USA: 'DEGREE_USA',
  TRAINING_GROUPS: 'TRAINING_GROUPS',
  GET_HOSPITAL_LEVELS: 'GET_HOSPITAL_LEVELS',
  PRIMARY_MED_CATEGORY: 'PRIMARY_MED_CATEGORY',
  SECONDARY_MED_CATEGORY: 'SECONDARY_MED_CATEGORY',
  TITLE_OPTIONS: 'TITLE_OPTIONS',
  GET_DOCTORS: 'GET_DOCTORS',
  GET_SHIPPERS: 'GET_SHIPPERS'
};


export const SCREEN_STATUS = {
  INITIAL_LOADING: 'INITIAL_LOADING_STATUS',
  LOADING: 'LOADING_STATUS',
  NULL: 'NULL_STATUS',
  ERROR: 'ERROR_STATUS'
};

export const EDUCATION_TYPE = {
  Internship: 'Internship',
  Residency: 'Residency',
  ChiefResidency: 'ChiefResidency',
  Fellowship: 'Fellowship',
  School: 'School'
};

export const USER_TYPE_SELECT = [
  { key: 'Both', value: 'Both', text: '全部用户' },
  { key: 'English', value: 'en', text: '英文用户' },
  { key: 'Chinese', value: 'zh', text: '中文用户' }
];

export const TAG_SHOW_SELECT = {
  None: '前端不显示',
  MedLinc: 'MedLinc显示',
  Care: '患者端显示',
  All: '两端均显示',
}

export const HOT_TAG_SELECT = [
  { key: 'isNotHot', value: 'isNotHot', text: '非热门标签' },
  { key: 'isHot', value: 'isHot', text: '热门标签' },
];
export const RECOMMEND_LABEL = {
  isHot: 'isHot',
  isNotHot: 'isNotHot'
};
export const HOT_TOPIC = {
  isHot: {
    Name: 'isHot',
    DisplayText: '热门标签'
  },
  isNotHot: {
    Name: 'isNotHot',
    DisplayText: '非热门标签'
  }
};
export const TAG_VISIBLE = {
  hidden: {
    Name: 'hidden',
    DisplayText: '前端不显示'
  },
  visible: {
    Name: 'visible',
    DisplayText: '前端显示'
  }
};
export const VISIBLE = {
  HIDDEN: 'hidden',
  VISIBLE: 'visible'
};
export const SEARCH_TYPE = {
  ALL_DEPARTMENTS: 'Department',
  ICD_TAG_IDS: 'Icd',
};

export const USER_TYPE_ENUM = {
  Both: '全部用户',
  English: '英文用户',
  Chinese: '中文用户'
};

export const selectTagFormItemLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 }
  },
  labelCol: {
    xs: { span: 12 },
    sm: { span: 4 }
  }
};

export const articleFormItemLayout = {
  labelCol: {
    xs: { span: 12 },
    sm: { span: 6 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 }
  }
};

export const selectFormItemLayout = {
  labelCol: {
    xs: { span: 23 },
    sm: { span: 2 }
  },
  wrapperCol: {
    xs: { span: 6 },
    sm: { span: 6 }
  }
};
export const articleformItemLayout = {
  labelCol: {
    xs: { span: 23 },
    sm: { span: 3 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};

export const FORM_ITEM_LAYOUT = {
  labelCol: {
    xs: { span: 12 },
    sm: { span: 2 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 }
  }
};

export const MODAL_FORM_ITEM_LAYOUT = {
  labelCol: {
    sm: { span: 4 },
    xs: { span: 12 },
  },
}

export const TAIL_FORM_ITEM_LAYOUT = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 2
    },
    sm: {
      span: 12,
      offset: 2
    }
  }
};

export const VIDEO_ACCEPT = 'video/mp4, video/wmv, video/avi, video/rmvb, video/mov, video/flv, video/rm'

export const RANGE_MAP = {
  Followed: {
    Name: 'Followed',
    DisplayText: '关注用户可见',
  },
  GroupWidePost: {
    Name: 'GroupWidePost',
    DisplayText: '对全部用户可见',
  },
  Tags: {
    Name: 'Tags',
    DisplayText: '标签用户可见',
  },
  RestrictedGroup: {
    Name: 'RestrictedGroup',
    DisplayText: '仅对机构成员可见',
  },
}

export const DISPLAY_RANGE_SELECT = {
  FEED: [{
    name: '对全部用户可见',
    value: RANGE_MAP.GroupWidePost.Name
  }, {
    name: '对关注用户可见',
    value: RANGE_MAP.Followed.Name
  }, {
    name: '仅对机构成员可见',
    value: RANGE_MAP.RestrictedGroup.Name
  }],
  OTHER: [{
    name: '对全部用户可见',
    value: RANGE_MAP.GroupWidePost.Name
  }, {
    name: '仅对机构成员可见',
    value: RANGE_MAP.RestrictedGroup.Name
  }]
}

export const CARE_CHANNELS = {
  DoctorQRCode: '医生二维码',
  WEB: 'H5',
  WEAPP: '小程序',
  GroupChat: '群聊会话',
  FriendSession: '好友会话',
  PublicNumber: '公众号菜单',
  OfficialAccountArticle: '公众号文章',
  SurveyPatientRegister: '预问诊量表',
  Offline: '线下物料',
  Diagnosis: '智能自诊',
  Moments: '朋友圈',
  jiaxing: '嘉兴',
  xuanchuanpian01: '宣传片01',
  WechatEnterprise: '企业微信渠道码', // 企业微信
  PreConsultationSurvey: '诊前预问诊', // 企业微信
  Cooperation: '对外合作',
}
export const CHANNELS = {
  huawei: 'huawei',
  sj360: 'sj360',
  google: 'google',
  wandoujia: 'wandoujia',
  vivo: 'vivo',
  xiaomi: 'xiaomi',
  zs91: 'zs91',
  android: 'android',
  meizu: 'meizu',
  ios: 'ios',
  oppo: 'oppo',
  baidu: 'baidu',
  siqi: 'siqi',
  toutiao: 'toutiao',
  tencent: 'tencent',
  wxpier88: 'wxpier88',
  wxmedlinc: 'wxmedlinc',
  AskBob: 'AskBob',
  ZhuanShuYiSheng: 'ZhuanShuYiSheng',
  Visitor: 'Visitor',
  H5Test: 'H5Test',
  p88hwebinar: 'p88hwebinar',
  medicine: 'medicine',
  guipei1: 'guipei1',
  guipei3: 'guipei3',
  guipei4: 'guipei4',
  xuanchuanpian01: '宣传片01',
};

export const UPLOAD_TYPE = {
  Picture: 'Picture',
  Video: 'Video'
}

export const BUTTON_TYPE = {
  POST: 'POST',
  DRAFT: 'DRAFT',
  SAVE: 'SAVE'
}

export const MEETING_STATUS = {
  Active: '已上架',
  Archived: '已删除',
  Draft: '草稿'
}

export const MEETING_STATUS_MAP = {
  Active: 'Active',
  Archived: 'Archived',
  Draft: 'Draft'
}

export const PAY_METHOD = {
  WeChatH5: '微信H5',
  WeChatJSAPI: '微信H5',
  WmpPay: '微信小程序',
  WeChatNative: '微信H5',
}
export const REFUND_STATUS = {
  Pending: 'Pending',
  Failed: 'Failed',
  Successful: 'Successful'
}
export const REFUND_STATUS_TEXT = {
  Pending: '退款中',
  Failed: '退款失败',
  Successful: '已退款'
}

export const GENDER_TYPE = {
  Male: '男',
  Female: '女',
}

export const OSS = 'https://care-prod.oss-cn-hangzhou.aliyuncs.com'

export const PLATFORM_TYPE = {
  App: 'app',
  Care: 'care'
}

export const PlatformObjData = {
  care: '小程序'
}

export const EVENT_TYPE_ENUM = {
  tap: '参数事件',
  num: '计算事件',
  page: '页面停留事件'
}

export const MEMBER_ROLE_IN_GROUP = {
  Regular: 'Regular',
  P8HAdmin: 'P8HAdmin'
}

export const WEEK_DAY_TYPE = {
  Monday: '周一',
  Tuesday: '周二',
  Wednesday: '周三',
  Thursday: '周四',
  Friday: '周五',
  Saturday: '周六',
  Sunday: '周日',
}

export const ACTION_TYPE = {
  Login: '登录',
  Logout: '退出登录',
  Create: '新增',
  Update: '修改',
  Delete: '删除',
  Get: '查看'
}

export enum SHOW_TYPE_ENUM {
  All = 'All',
  MedLinc = 'MedLinc',
  Care = 'Care',
  None = 'None',
}

export const ACQUIRE_CHANNEL = {
  ChunYu: '春雨医生'
}
