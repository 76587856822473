import getDynamicComponent from '@COMS/DynamicComponent';
import { strings } from '@I18N/i18n';
import { MEMBER_ROLE_IN_GROUP } from '@COMMON/constant'
import DisabledRouter from '@COMS/AuthorityRoute/DisabledRouter'
import {
  HomeOutlined, UserOutlined, ProjectOutlined, FormOutlined, BookOutlined,
  GatewayOutlined, SolutionOutlined, UserAddOutlined, ToolOutlined, PictureOutlined,
  ReconciliationOutlined, UsergroupAddOutlined, CommentOutlined, UnorderedListOutlined, TeamOutlined,
  LaptopOutlined, TagsOutlined, FileSearchOutlined, GiftOutlined, ReadOutlined, EditOutlined,
  UserSwitchOutlined, DatabaseOutlined, DeploymentUnitOutlined, AuditOutlined, AppstoreOutlined
} from '@ant-design/icons';
// import { userInfo } from 'os';

// 异步加载
const BasicLayout = () => import('@LAYOUT/BasicLayout')
const Main = () => import('@SCREENS/Main/Main')

const PlatformOperation = () => import('@SCREENS/SecurityAudit/PlatformOperation')

const AddFeed = () => import('@SCREENS/Feed/AddFeed')
const ArticleFeed = () => import('@SCREENS/Feed/ArticleFeed')
const OwnFeed = () => import('@SCREENS/Feed/OwnFeed')
const ArticleList = () => import('@SCREENS/Feed/ArticleList')
const UserFeed = () => import('@SCREENS/Feed/UserFeed')
const HospitalFeed = () => import('@SCREENS/Feed/HospitalFeed')

const PostOpportunity = () => import('@SCREENS/Opportunity/PostOpportunity')
const OwnOpportunityList = () => import('@SCREENS/Opportunity/OwnOpportunityList')
const PublicOpportunity = () => import('@SCREENS/Opportunity/PublicOpportunity')
// const CustomerOpportunity = () => import('@SCREENS/Opportunity/CustomerOpportunity')
const ApplicationCount = () => import('@SCREENS/Opportunity/ApplicationCount')
const ApplyOpportunityList = () => import('@SCREENS/Opportunity/ApplyOpportunityList')

const AddGroup = () => import('@SCREENS/Group/AddGroup')
const GroupList = () => import('@SCREENS/Group/GroupList')
const GroupFeedList = () => import('@SCREENS/Group/GroupFeedList')
const GroupMemberList = () => import('@SCREENS/Group/GroupMemberList')
const FollowerList = () => import('@SCREENS/Group/FollowerList')
const MaintainerManage = () => import('@SCREENS/Group/MaintainerManage')

const AddDeliver = () => import('@SCREENS/Operator/AddDeliver')
const DeliverList = () => import('@SCREENS/Operator/DeliverList')
const AddEvents = () => import('@SCREENS/Operator/AddEvents')
const EventList = () => import('@SCREENS/Operator/EventList')
const AnalyticList = () => import('@SCREENS/Operator/AnalyticList')
const ChatList = () => import('@SCREENS/Operator/ChatList')
const MeetupList = () => import('@SCREENS/Meetup/MeetupList')
const CreateMeetup = () => import('@SCREENS/Meetup/CreateMeetup')

const UserManage = () => import('@SCREENS/UserManage')
const InvitedUserList = () => import('@SCREENS/User/InviteNumberList')
const EditUser = () => import('@SCREENS/User/EditUser')
const InviteUserNumbersList = () => import('@SCREENS/User/InviteUserNumbersList')

const AddGuide = () => import('@SCREENS/Guide/AddGuide')
const GuideList = () => import('@SCREENS/Guide/GuideList')

const PostVideoCourse = () => import('@SCREENS/Course/PostVideoCourse')
const VideoCourseList = () => import('@SCREENS/Course/VideoCourseList')

const OriginalLabel = () => import('@SCREENS/Label/OriginalLabel')
const CustomLabel = () => import('@SCREENS/Label/CustomLabel')
const AddLabel = () => import('@SCREENS/Label/AddLabel')

const AdminOrder = () => import('@SCREENS/Order/AdminOrder')

const ManageComment = () => import('@SCREENS/Comment/ManageComment')

const CreateMedWord = () => import('@SCREENS/MedWord/CreateMedWord')
const MedWordList = () => import('@SCREENS/MedWord/MedWordList')

const DoctorList = () => import('@SCREENS/Care/DoctorList')
const CareUserList = () => import('@SCREENS/Care/CareUserList')
const CarePatientList = () => import('@SCREENS/Care/CarePatientList')
const EditDoctor = () => import('@SCREENS/Care/EditDoctor')
const ConsultationOrderList = () => import('@SCREENS/Care/ConsultationOrderList')
const RatingsList = () => import('@SCREENS/Care/RatingsList')
const AddOperationEvents = () => import('@SCREENS/Care/AddOperationEvents')
const OperationEventList = () => import('@SCREENS/Care/OperationEventList')
const FollowUpList = () => import('@SCREENS/Care/FollowUpList')
const ServicesManageList = () => import('@SCREENS/Care/ServicesManageList')
const PainManagementOrderList = () => import('@SCREENS/Care/PainManagementOrderList')
const SurveyList = () => import('@SCREENS/Care/SurveyList')
const PainManagementList = () => import('@SCREENS/Care/PainManagementList')
const PlanUserList = () => import('@SCREENS/Care/PlanUserList')

const ManageDataList = () => import('@SCREENS/ManageData/ManageDataList')

const MedicineList = () => import('@SCREENS/Medicine/MedicineList')
const EditMedicine = () => import('@SCREENS/Medicine/EditMedicine')
const PrescriptionList = () => import('@SCREENS/ManagePrescription/PrescriptionList')
const PharmacistList = () => import('@SCREENS/ManagePrescription/PharmacistList')
const DrugsList = () => import('@SCREENS/ManagePrescription/DrugsList')
const CreatePharmacist = () => import('@SCREENS/ManagePrescription/CreatePharmacist')

const baseRouterMap = {
  '/': {
    component: getDynamicComponent(BasicLayout),
    children: {
      '/main': {
        name: 'Main',
        icon: HomeOutlined,
        component: getDynamicComponent(Main)
      },
      '/feed/groupFeed/:id': {
        name: strings('menu.groupFeed'),
        icon: UnorderedListOutlined,
        authority: 'admin',
        hidden: true,
        component: getDynamicComponent(GroupFeedList)
      },
      '/institution/organization/:gId': {
        authority: 'admin',
        hidden: true,
        component: getDynamicComponent(GroupMemberList)
      },
      '/user/follower/:id': {
        name: strings('menu.follower'),
        hidden: true,
        authority: 'admin',
        component: getDynamicComponent(FollowerList)
      },
      '/opportunity/opportunityList/:id': {
        hidden: true,
        authority: 'admin',
        component: getDynamicComponent(ApplicationCount)
      },
      '/user/InviteUserNumbersList/:id': {
        hidden: true,
        authority: 'admin',
        component: getDynamicComponent(InviteUserNumbersList)
      },
      '/care/editDoctor/:id': {
        hidden: true,
        authority: 'admin',
        component: getDynamicComponent(EditDoctor)
      },
      '/medicine/editMedicine': {
        hidden: true,
        authority: 'admin',
        component: getDynamicComponent(EditMedicine)
      }
    }
  }
};

const SecurityAudit = {
  name: strings('menu.security_audit'),
  icon: AuditOutlined,
  authority: 'admin',
  children: {
    '/securityAudit/platformOperation': {
      name: strings('menu.platform_operation'),
      icon: AppstoreOutlined,
      authority: 'admin',
      component: getDynamicComponent(PlatformOperation)
    },
  }
}

const manageGlobalMembers = {
  name: strings('menu.user'),
  icon: UserOutlined,
  authority: 'admin',
  children: {
    '/user/userList': {
      name: strings('menu.userList'),
      icon: UserOutlined,
      authority: 'admin',
      component: getDynamicComponent(UserManage)
    },
    '/user/invitedList': {
      name: strings('menu.invitedList'),
      icon: UserOutlined,
      authority: 'admin',
      component: getDynamicComponent(InvitedUserList)
    }
  }
};
const editUserRoute = {
  name: strings('menu.editUser'),
  hidden: true,
  authority: 'admin',
  component: getDynamicComponent(EditUser)
}
const createOpportunityAdmin = {
  name: strings('menu.opportunity'),
  icon: ProjectOutlined,
  authority: 'admin',
  children: {
    '/opportunity/postOpportunity': {
      name: strings('menu.post_opportunity'),
      icon: FormOutlined,
      authority: 'admin',
      component: window.isHNEnv ? DisabledRouter : getDynamicComponent(PostOpportunity)
    },
    '/opportunity/ownOpportunityList': {
      name: strings('menu.own_opportunity_list'),
      icon: BookOutlined,
      authority: 'admin',
      component: getDynamicComponent(OwnOpportunityList)
    }
  }
};
const publicOpportunity = {
  name: strings('menu.public_opportunity'),
  icon: GatewayOutlined,
  authority: 'admin',
  component: getDynamicComponent(PublicOpportunity)
};
const applyOpportunityList = {
  name: strings('menu.apply_opportunity'),
  icon: BookOutlined,
  authority: 'admin',
  component: getDynamicComponent(ApplyOpportunityList)
};
// const customerOpportunity = {
//   name: strings('menu.customer_opportunity'),
//   icon: GatewayOutlined,
//   authority: 'admin',
//   component: getDynamicComponent(CustomerOpportunity)
// };
const postFeed = {
  name: strings('menu.feed'),
  authority: 'admin', // 以后这里应该改成内容发布人和admin
  icon: SolutionOutlined,
  children: {
    '/feed/addFeed': {
      name: strings('menu.addFeed'),
      authority: 'admin',
      icon: FormOutlined,
      component: getDynamicComponent(AddFeed)
    },
    '/feed/articleFeed': {
      name: strings('menu.addRichFeed'),
      authority: 'admin',
      icon: FormOutlined,
      component: getDynamicComponent(ArticleFeed)
    },
    '/feed/articleList': {
      name: strings('menu.articleList'),
      icon: UnorderedListOutlined,
      authority: 'admin',
      component: getDynamicComponent(ArticleList)
    },
    '/feed/feedOwnFeed': {
      name: strings('menu.ownFeed'),
      icon: UnorderedListOutlined,
      authority: 'admin',
      component: getDynamicComponent(OwnFeed)
    }
  }
};
const manageFeed = {
  name: strings('menu.userFeed'),
  icon: SolutionOutlined,
  authority: 'admin',
  component: getDynamicComponent(UserFeed)
};
const hospitalFeed = {
  name: strings('menu.hospitalFeed'),
  icon: PictureOutlined,
  authority: 'admin',
  component: getDynamicComponent(HospitalFeed)
};
const manageAllGroups = {
  name: strings('menu.institution'),
  icon: ReconciliationOutlined,
  authority: 'admin',
  children: {
    '/institution/addGroup': {
      name: strings('menu.addGroup'),
      icon: UsergroupAddOutlined,
      authority: 'admin',
      component: window.isHNEnv ? DisabledRouter : getDynamicComponent(AddGroup)
    },
    '/institution/organization': {
      name: strings('menu.organization'),
      icon: UnorderedListOutlined,
      authority: 'admin',
      component: getDynamicComponent(GroupList)
    }
  }
};
const manageModerator = {
  name: strings('menu.maintainer'),
  icon: UserAddOutlined,
  authority: 'admin',
  component: getDynamicComponent(MaintainerManage)
};

const manageChatList = {
  name: strings('menu.chatList'),
  icon: UnorderedListOutlined,
  authority: 'admin',
  component: getDynamicComponent(ChatList)
}

const operator = {
  name: strings('menu.operator'),
  icon: ToolOutlined,
  authority: 'admin',
  children: {
    '/tool/addDeliver': {
      name: strings('menu.addDeliver'),
      icon: FormOutlined,
      authority: 'admin',
      component: getDynamicComponent(AddDeliver)
    },
    '/tool/deliverList': {
      name: strings('menu.deliverList'),
      icon: UnorderedListOutlined,
      authority: 'admin',
      component: getDynamicComponent(DeliverList)
    },
    '/tool/addEvents': {
      name: strings('menu.add_events'),
      icon: FormOutlined,
      authority: 'admin',
      component: getDynamicComponent(AddEvents)
    },
    '/tool/eventsList': {
      name: strings('menu.eventList'),
      icon: UnorderedListOutlined,
      authority: 'admin',
      component: getDynamicComponent(EventList)
    },
    '/tool/analyticList': {
      name: strings('menu.analyticList'),
      icon: UnorderedListOutlined,
      authority: 'admin',
      component: getDynamicComponent(AnalyticList)
    }
  }
};

const Comment = {
  name: strings('menu.manage_comment'),
  icon: CommentOutlined,
  authority: 'admin',
  children: {
    '/comment/manageComment': {
      name: strings('menu.comment_list'),
      icon: UnorderedListOutlined,
      authority: 'admin',
      component: getDynamicComponent(ManageComment)
    }
  }
}

const ManageMeeting = {
  name: strings('menu.manage_eeting'),
  icon: TeamOutlined,
  authority: 'admin',
  children: {
    '/meetup/createMeetup': {
      name: strings('menu.create_meeting'),
      icon: FormOutlined,
      authority: 'admin',
      component: window.isHNEnv ? DisabledRouter : getDynamicComponent(CreateMeetup)
    },
    '/meetup/meetupList': {
      name: strings('menu.meeting_list'),
      icon: UnorderedListOutlined,
      authority: 'admin',
      component: getDynamicComponent(MeetupList)
    }
  }
}

const guide = {
  name: strings('menu.guide'),
  icon: BookOutlined,
  authority: 'admin',
  children: {
    '/guide/addGuide': {
      name: strings('menu.addGuide'),
      icon: FormOutlined,
      authority: 'admin',
      component: getDynamicComponent(AddGuide)
    },
    '/guide/guideList': {
      name: strings('menu.guideList'),
      icon: UnorderedListOutlined,
      authority: 'admin',
      component: getDynamicComponent(GuideList)
    }
  }
};
// vedio course
const videoCourse = {
  name: strings('menu.course'),
  icon: LaptopOutlined,
  authority: 'admin',
  children: {
    '/videoCourse/postVideoCourse': {
      name: strings('control.create_course'),
      icon: FormOutlined,
      authority: 'admin',
      component: getDynamicComponent(PostVideoCourse)
    },
    '/videoCourse/videoCourseList': {
      name: strings('menu.courseList'),
      icon: UnorderedListOutlined,
      authority: 'admin',
      component: getDynamicComponent(VideoCourseList)
    }
  }
};
// Label Library
const labelLibrary = {
  name: strings('label.manage'),
  icon: TagsOutlined,
  authority: 'admin',
  children: {
    '/label/originalLibrary': {
      name: strings('label.original_library'),
      icon: TagsOutlined,
      authority: 'admin',
      component: getDynamicComponent(OriginalLabel)
    },
    '/label/customLabel': {
      name: strings('label.custom'),
      icon: TagsOutlined,
      authority: 'admin',
      component: getDynamicComponent(CustomLabel)
    },
    '/label/addLabel': {
      name: strings('label.add_label'),
      icon: FormOutlined,
      authority: 'admin',
      component: getDynamicComponent(AddLabel)
    }
  }
};

const PointOrders = {
  name: strings('menu.admin_order'),
  icon: FileSearchOutlined,
  authority: 'admin',
  children: {
    '/order/AdminOrder': {
      name: strings('order.gift_recode'),
      icon: GiftOutlined,
      authority: 'admin',
      component: getDynamicComponent(AdminOrder)
    }
  }
}

const MedWord = {
  name: strings('menu.manage_medicalEnglish'),
  icon: ReadOutlined,
  authority: 'admin',
  children: {
    '/medWord/CreateMedWord': {
      name: strings('menu.add_medicalEnglish'),
      icon: EditOutlined,
      authority: 'admin',
      component: window.isHNEnv ? DisabledRouter : getDynamicComponent(CreateMedWord)
    },
    '/medWord/MedWordList': {
      name: strings('menu.medicalEnglish_list'),
      icon: UnorderedListOutlined,
      authority: 'admin',
      component: getDynamicComponent(MedWordList)
    }
  }
}

const Care = {
  name: strings('menu.patient_service'),
  icon: UserSwitchOutlined,
  authority: 'admin',
  children: {
    '/care/doctorList': {
      name: strings('care.doctor_list'),
      icon: UnorderedListOutlined,
      authority: 'admin',
      component: getDynamicComponent(DoctorList)
    },
    '/care/careUserList': {
      name: strings('care.patient_list'),
      icon: UnorderedListOutlined,
      authority: 'admin',
      component: getDynamicComponent(CareUserList)
    },
    '/care/consultationOrderList': {
      name: strings('care.consultationOrderList'),
      icon: UnorderedListOutlined,
      authority: 'admin',
      component: getDynamicComponent(ConsultationOrderList)
    },
    '/care/ratingsList': {
      name: strings('menu.ratings_list'),
      icon: UnorderedListOutlined,
      authority: 'admin',
      component: getDynamicComponent(RatingsList)
    },
    '/care/operationEventList': {
      name: strings('menu.eventList'),
      icon: UnorderedListOutlined,
      authority: 'admin',
      component: getDynamicComponent(OperationEventList)
    },
    '/care/addOperationEvents': {
      name: strings('menu.add_events'),
      icon: FormOutlined,
      authority: 'admin',
      component: getDynamicComponent(AddOperationEvents)
    },
    '/care/carePatientList': {
      name: strings('menu.patient_list'),
      icon: UnorderedListOutlined,
      authority: 'admin',
      component: getDynamicComponent(CarePatientList)
    },
    '/care/followUpList': {
      name: strings('menu.follow_up_list'),
      icon: UnorderedListOutlined,
      authority: 'admin',
      component: getDynamicComponent(FollowUpList)
    },
    '/care/servicesManageList': {
      name: strings('menu.services_list'),
      icon: UnorderedListOutlined,
      authority: 'admin',
      component: getDynamicComponent(ServicesManageList)
    },
    '/care/surveyList': {
      name: strings('menu.survey_list'),
      icon: UnorderedListOutlined,
      authority: 'admin',
      component: getDynamicComponent(SurveyList)
    },
    '/care/painManagementList': {
      name: strings('menu.pain_management'),
      icon: UnorderedListOutlined,
      authority: 'admin',
      component: getDynamicComponent(PainManagementList)
    },
    '/care/planUserList': {
      name: strings('menu.plan_user_list'),
      icon: UnorderedListOutlined,
      authority: 'admin',
      component: getDynamicComponent(PlanUserList)
    },
    '/care/painManagementOrderList': {
      name: strings('menu.services_list_new'),
      icon: UnorderedListOutlined,
      authority: 'admin',
      component: getDynamicComponent(PainManagementOrderList)
    },
  }
}

const DataManage = {
  name: strings('menu.data_manage'),
  icon: DatabaseOutlined,
  authority: 'admin',
  children: {
    '/dataManage/manageDataList': {
      name: strings('menu.data_list'),
      icon: UnorderedListOutlined,
      authority: 'admin',
      component: getDynamicComponent(ManageDataList)
    }
  }
}

const Medicine = {
  name: strings('menu.medicine_manage'),
  icon: DeploymentUnitOutlined,
  authority: 'admin',
  children: {
    '/medicine/medicineList': {
      name: strings('menu.medicine_list'),
      icon: UnorderedListOutlined,
      authority: 'admin',
      component: getDynamicComponent(MedicineList)
    },
  },
}

const PreviewPrescription = {
  name: strings('menu.prescription_manage'),
  icon: DatabaseOutlined,
  authority: 'admin',
  children: {
    '/managePrescription/prescriptionList': {
      name: strings('menu.prescription_list'),
      icon: UnorderedListOutlined,
      authority: 'admin',
      component: getDynamicComponent(PrescriptionList)
    },
  }
}

const ManagePrescription = {
  name: strings('menu.prescription_manage'),
  icon: DatabaseOutlined,
  authority: 'admin',
  children: {
    '/managePrescription/prescriptionList': {
      name: strings('menu.prescription_list'),
      icon: UnorderedListOutlined,
      authority: 'admin',
      component: getDynamicComponent(PrescriptionList)
    },
    '/managePrescription/pharmacistList': {
      name: strings('menu.pharmacist_list'),
      icon: UnorderedListOutlined,
      authority: 'admin',
      component: getDynamicComponent(PharmacistList)
    },
    '/managePrescription/drugsList': {
      name: strings('menu.drugs_list'),
      icon: UnorderedListOutlined,
      authority: 'admin',
      component: getDynamicComponent(DrugsList)
    },
    '/managePrescription/createPharmacist': {
      name: strings('menu.create_pharmacist'),
      icon: EditOutlined,
      authority: 'admin',
      component: getDynamicComponent(CreatePharmacist)
    },
  }
}

const getRouterMapping = (isAdmin, memberRoleInGroup) => {
  const adminRouterMap = { ...baseRouterMap };
  if (memberRoleInGroup === MEMBER_ROLE_IN_GROUP.Regular && isAdmin.ReviewPrescription) {
    adminRouterMap['/'].children['/managePrescription'] = PreviewPrescription
    return adminRouterMap
  }

  if (isAdmin.ManageAuditLog) {
    adminRouterMap['/'].children['/securityAudit'] = SecurityAudit
  }

  if (isAdmin.ManageGlobalMembers) {
    adminRouterMap['/'].children['/user'] = manageGlobalMembers;
    adminRouterMap['/'].children['/user/editUser'] = editUserRoute;
  }
  if (isAdmin.CreatePost) {
    adminRouterMap['/'].children['/feed'] = postFeed;
    if (isAdmin.ManageFeed) {
      adminRouterMap['/'].children['/feed'].children[
        '/feed/feedUserFeed'
      ] = manageFeed;
      adminRouterMap['/'].children['/feed'].children[
        '/feed/HospitalFeed'
      ] = hospitalFeed;
    }
  }
  if (isAdmin.CreateOpportunityAdmin) {
    adminRouterMap['/'].children['/opportunity'] = createOpportunityAdmin;
    if (isAdmin.ManageOpportunityGlobal) {
      adminRouterMap['/'].children['/opportunity'].children[
        '/opportunity/publicOpportunity'
      ] = publicOpportunity;
      // adminRouterMap['/'].children['/opportunity'].children[
      //   '/opportunity/customerOpportunity'
      // ] = customerOpportunity;
    }
    if (isAdmin.ManageApplicationAdmin) {
      adminRouterMap['/'].children['/opportunity'].children[
        '/opportunity/applyOpportunityList'
      ] = applyOpportunityList;
    }
  }
  if (isAdmin.ManageAllGroups) {
    adminRouterMap['/'].children['/institution'] = manageAllGroups;
    if (isAdmin.ManageModerators) {
      adminRouterMap['/'].children['/institution'].children[
        '/institution/maintainer'
      ] = manageModerator;
    }
  }
  if (isAdmin.ManageTriggers) {
    adminRouterMap['/'].children['/tool'] = operator;
    adminRouterMap['/'].children['/tool'].children['/tool/chatList'] = manageChatList;
  }
  if (isAdmin.ManageGuideAdmin) {
    adminRouterMap['/'].children['/guide'] = guide;
  }
  if (isAdmin.ManageCourse) {
    adminRouterMap['/'].children['/videoCourse'] = videoCourse;
  }
  if (isAdmin.ManageTag) {
    adminRouterMap['/'].children['/label'] = labelLibrary;
  }
  if (isAdmin.ManageOrders) {
    adminRouterMap['/'].children['/order'] = PointOrders
  }

  if (isAdmin.ManageMeetups) {
    adminRouterMap['/'].children['/meetup'] = ManageMeeting
  }
  if (isAdmin.CommentFeedItem) {
    adminRouterMap['/'].children['/comment'] = Comment
  }
  if (isAdmin.ManageMedWords) {
    adminRouterMap['/'].children['/medWord'] = MedWord
  }
  if (isAdmin.ManagePatientCare) {
    if (!isAdmin.SeeCareUsers) {
      delete Care.children['/care/careUserList']
    }
    if (!isAdmin.SeeCarePatients) {
      delete Care.children['/care/carePatientList']
    }
    adminRouterMap['/'].children['/care'] = Care
  }
  if (isAdmin.DownloadUserInfo) {
    adminRouterMap['/'].children['/dataManage'] = DataManage
  }
  if (isAdmin.ManageMedicines) {
    adminRouterMap['/'].children['/medicine'] = Medicine
  }
  if (isAdmin.ManagePrescription) {
    adminRouterMap['/'].children['/managePrescription'] = ManagePrescription
  } else if (isAdmin.ReviewPrescription) {
    adminRouterMap['/'].children['/managePrescription'] = PreviewPrescription
  }
  return adminRouterMap;
};

export default getRouterMapping
